export default {
  cake: {
    137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
  },
  masterChef: {
    137: '0xd3976E92a48821DD1122Ae5e8265b14595aF34d2',
  },
  vaultChef: {
    137: '0x871DaC821e428Dd4C688cc42d630021C34aF07FF',
  },
  lottery: {
    137: '',
  },
  theEndlessFactoryNFT: {
    137: '0x743F554f6AcCd4E452AF6C96c48B78E849d87316',
    // 137: '0x2487DF8D510cC0A9ec99fcE1F1d43adb3F6C731d', // TEST
  },
  nftSale: {
    137: '0xb58175a4a6293eDBe24Db9353F7Bf513c7e84399',
    // 137: '0xdB7027F06EcD0f4CFAA93996a926C48ad7AE94f8', // TEST
  },
  deliriumReferral: {
    137: '0xFDdDeacd2Ffc5F5Da430265430427F2669d2eA14',
  },
  delightToken: {
    137: '0x960fE3CBEF6d922eCe9fa3E5611B8f9Ec14DE649',
  },
  delightPresaleVip: {
    137: '0x5742477df10C1cC973e1B9A6542d588BfF093D99',
  },
  delightMadness: {
    137: '0xcDe66ca86126fD8168B84Ff4f07c06832FB4f529',
  },
  lotteryNFT: {
    137: '',
  },
  mulltiCall: {
    137: '0xEF3d4160E3d193E3d1494a4A9cF9eEA05E63a324',
  },
  wmatic: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  matic: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  wbnb: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  bnb: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  wbtc: {
    137: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  },
  weth: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  eth: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  usdc: {
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    // 137: '0xCB19E282718Ea89666E60FA6Db939aDd02fc9ae9', // TEST
  },
  busd: {
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  usdt: {
    137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  dai: {
    137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  quick: {
    137: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
  },
  link: {
    137: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
  },
  sandman: {
    137: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
  },
}
