import BigNumber from 'bignumber.js'
// import { useEffect, useMemo } from 'react'
import { useEffect } from 'react'
import { BLOCKS_PER_YEAR } from 'config'
import { useSelector, useDispatch } from 'react-redux'
import { getApy } from 'utils/compoundApyHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmsPublicDataAsync, fetchPoolsUserDataAsync, fetchPoolsPublicDataAsync } from './actions'
// import { fetchFarmsPublicDataAsync, fetchPoolsUserDataAsync, fetchPoolsPublicDataAsync, fetchVaultsPublicDataAsync } from './actions'
import { State, Farm, Pool, Vault } from './types'
import { QuoteToken } from '../config/constants/types'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    // dispatch(fetchVaultsPublicDataAsync())
    dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceCakeBusd = (): BigNumber => {
  const pid = 0
  const farm = useFarmFromPid(pid)

  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceBnbBusd = (): BigNumber => {
  const pid = 3
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

// TVL CALC

export const useTotalValue = (account): BigNumber[] => {
  const farms = useFarms()
  const pools = usePools(account)
  const bnbPrice = usePriceBnbBusd()
  const cakePrice = usePriceCakeBusd()
  let value = new BigNumber(0)
  let tnl = new BigNumber(0)
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val
      if (farm.quoteTokenSymbol === QuoteToken.BNB) {
        val = bnbPrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
        val = cakePrice.times(farm.lpTotalInQuoteToken)
      } else {
        val = farm.lpTotalInQuoteToken
      }
      if (typeof val === 'object' && val.isNaN()) {
        val = new BigNumber(0)
      }
      if (typeof val === 'string' && val === 'NaN') {
        val = '0'
      }
      value = value.plus(val)
      if (farm.lpSymbol.indexOf(process.env.REACT_APP_TOKEN_SYMBOL) >= 0) {
        tnl = tnl.plus(val)
      }
    }
  }
  for (let i = 0; i < (pools || []).length; i++) {
    const pool = pools[i]

    const totalStaked = new BigNumber(pool.totalStaked).div(new BigNumber(10).pow(18))
    let val = totalStaked
    if (pool.stakingTokenName === process.env.REACT_APP_TOKEN_SYMBOL) {
      val = cakePrice.times(val)
    }

    value = value.plus(val)
    if (pool.stakingTokenName.indexOf(process.env.REACT_APP_TOKEN_SYMBOL) >= 0) {
      tnl = tnl.plus(val)
    }
  }
  return [value, tnl]
}

export const useVaults = (): Vault[] => {
  const vaults = useSelector((state: State) => state.vaults.data)
  return vaults
}

export const useVaultFromPid = (pid): Vault => {
  const vault = useSelector((state: State) => state.vaults.data.find((f) => f.pid === pid))
  return vault
}

export const useVaultFromSymbol = (lpSymbol: string): Vault => {
  const vault = useSelector((state: State) => state.vaults.data.find((f) => f.lpSymbol === lpSymbol))
  return vault
}

export const useVaultUser = (pid) => {
  const vault = useVaultFromPid(pid)

  return {
    allowance: vault.userData ? new BigNumber(vault.userData.allowance) : new BigNumber(0),
    tokenBalance: vault.userData ? new BigNumber(vault.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: vault.userData ? new BigNumber(vault.userData.stakedBalance) : new BigNumber(0),
    earnings: vault.userData ? new BigNumber(vault.userData.earnings) : new BigNumber(0),
  }
}

export const useFarmsEarnUpTo = (isTokenOnly): BigNumber => {
  const farms = useFarms()
  const bnbPrice = usePriceBnbBusd()
  const cakePrice = usePriceCakeBusd()

  const apys: number[] = farms.map((farm) => {
    if (!!farm.isTokenOnly !== isTokenOnly) {
      return 0
    }

    const dec = 18
    const cakeRewardPerBlock = new BigNumber(farm.deliriumPerBlock || 1)
      .times(new BigNumber(farm.poolWeight))
      .div(new BigNumber(10).pow(dec))
  
    const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR)

    let apr = new BigNumber(0)
    let apy = new BigNumber(0)

    if (process.env.REACT_APP_PRICE_DEFAULT || cakePrice.isNaN()) {
      apr = new BigNumber(10.00).times(cakeRewardPerYear)
    } else {
      apr = cakePrice.times(cakeRewardPerYear)
    }
  
    let totalValue = new BigNumber(farm.lpTotalInQuoteToken || 0)
  
    if (farm.quoteTokenSymbol === QuoteToken.BNB) {
      totalValue = totalValue.times(bnbPrice)
    }
  
    if (totalValue.comparedTo(0) > 0) {
      apr = apr.div(totalValue)
    }

    const apyNumber = apr.times(new BigNumber(100)).toNumber()
    apy = new BigNumber(getApy(apyNumber, 1, 365, 0))

    return apy.times(new BigNumber(100)).toNumber()
  })

  return new BigNumber(Math.max(...apys))
}

export const useVaultsEarnUpTo = (): BigNumber => {
  const vaults = useVaults()
  const bnbPrice = usePriceBnbBusd()
  const cakePrice = usePriceCakeBusd()

  const apys: number[] = vaults.map((vault) => {
    let apr = new BigNumber(0)
    let apy = new BigNumber(0)

    const cakeRewardPerBlock = new BigNumber(vault.deliriumPerBlock || 1)
      .times(new BigNumber(vault.poolWeight))
      .div(new BigNumber(10).pow(18))
    const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR)

    if (process.env.REACT_APP_PRICE_DEFAULT) {
      apr = new BigNumber(process.env.REACT_APP_PRICE_DEFAULT).times(cakeRewardPerYear)
    } else {
      apr = cakePrice.times(cakeRewardPerYear)
    }

    let totalValue = new BigNumber(vault.lpTotalInQuoteTokenFarm || 0)

    if (vault.quoteTokenSymbol === QuoteToken.BNB) {
      totalValue = totalValue.times(bnbPrice)
    }

    if (totalValue.comparedTo(0) > 0) {
      apr = apr.div(totalValue)
    }

    const apyNumber = apr.times(new BigNumber(100)).toNumber()
    apy = new BigNumber(getApy(apyNumber, vault.compoundFrequency, 365, vault.performanceFee))

    return apy.times(new BigNumber(100)).toNumber()
  })

  return new BigNumber(Math.max(...apys))
}

export const usePoolsEarnUpTo = (account): BigNumber => {
  const pools = usePools(account)
  const farms = useFarms()

  const apys: number[] = pools.map((pool) => {
    if (pool.isFinished) {
      return 0
    }

    const stakingTokenFarm = farms.find((s) => s.lpSymbol === `${pool.stakingTokenName}-MATIC LP`)

    const stakingTokenPriceInBNB = new BigNumber(stakingTokenFarm?.tokenPriceVsQuote)
    const rewardTokenPriceInBNB = new BigNumber(pool.tokenPriceVsQuote)

    const totalRewardPricePerYear = rewardTokenPriceInBNB.times(pool.tokenPerBlock).times(BLOCKS_PER_YEAR)
    const totalStakingTokenInPool = stakingTokenPriceInBNB.times(getBalanceNumber(pool.totalStaked))
    let apy = totalRewardPricePerYear

    if (totalStakingTokenInPool.comparedTo(0) > 0) {
      apy = apy.div(totalStakingTokenInPool)
    }

    return apy.times(new BigNumber(100)).toNumber()
  })

  return new BigNumber(Math.max(...apys))
}
