import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getUsdcAddress = () => {
  return addresses.usdc[chainId]
}
export const getSandManAddress = () => {
  return addresses.sandman[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getVaultChefAddress = () => {
  return addresses.vaultChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getSandmanReferralAddress = () => {
  return addresses.deliriumReferral[chainId]
}
export const getDelightTokenAddress = () => {
  return addresses.delightToken[chainId]
}
export const getDelightPresaleVipAddress = () => {
  return addresses.delightPresaleVip[chainId]
}
export const getDelightMadnessAddress = () => {
  return addresses.delightMadness[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
export const getTheEndlessFactoryNFTAddress = () => {
  return addresses.theEndlessFactoryNFT[chainId]
}
export const getNftSaleAddress = () => {
  return addresses.nftSale[chainId]
}
