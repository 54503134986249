import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  /* prettier-ignore */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  /* prettier-ignore */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-size: 16px;
  }
  ol,
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
  *, html, body, a, p, div, span, b, i {
    font-family: 'Roboto Mono', sans-serif;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  .home-page {
    background-image: url('/images/delirium/bg.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100% auto;
  }

  .dream-kingdom-page {
    background-image: url('/images/delirium/bg-madness-kingdom.svg');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% auto;
  }

  .endless-page {
    background-image: url('/images/delirium/bg-endless.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
  }

  .presale-page {
    background-image: url('/images/delirium/bg-presale-mobile.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto 66%;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    .home-page {
      background-image: url('/images/delirium/bg.svg');
      background-size: 75% auto;
    }
  
    .dream-kingdom-page {
      background-position: bottom right;
      background-size: 66% auto;
    }

    .presale-page {
      background-image: url('/images/delirium/bg-presale.svg');
      background-position: center bottom;
      background-size: auto 90%;
    }  
  }  
`

export default GlobalStyle
