import { PoolConfig, PoolCategory } from './types'
// import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 6,
    tokenName: 'COLLAR',
    stakingTokenName: 'DELIRIUM',
    stakingTokenAddress: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    contractAddress: {
      137: '0x16DD6adbf75Ee6386ED0f7cD56593176c005E2F0',
    },
    lpAddress: {
      137: '0x45cE6Bd7a2eF64bf980559C64e602B79f2a0d8f0',
    },
    tokenAddress: {
      137: '0x8DF26a1BD9bD98e2eC506fc9d8009954716A05DC',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://polypup.finance/',
    harvest: true,
    tokenPerBlock: '0.005986111111',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 5000,
    withdrawLockup: 48,
    harvestLockup: 4,
    partnerColor: '219, 247, 255',
    experienceBalance: 20000,
  },
  {
    sousId: 5,
    tokenName: 'ALPHA',
    stakingTokenName: 'DELIRIUM',
    stakingTokenAddress: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    contractAddress: {
      137: '0xC868473DBF34b8944124C10F3276Cb2C8Fd6b162',
    },
    lpAddress: {
      137: '0x79b368B3Aa34fF2044F7869024b5367A284D3b0E',
    },
    tokenAddress: {
      137: '0x0B048D6e01a6b9002C291060bF2179938fd8264c',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://polyalpha.finance/',
    harvest: true,
    tokenPerBlock: '0.0003425925926',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 5000,
    withdrawLockup: 72,
    harvestLockup: 4,
    partnerColor: '119, 81, 216',
    experienceBalance: 30000,
  },
  {
    sousId: 4,
    tokenName: 'MAI',
    stakingTokenName: 'DELIRIUM',
    stakingTokenAddress: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    contractAddress: {
      137: '0xD923E948a83660023BC6aDAF01635eFADbC51a52',
    },
    lpAddress: {
      137: '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954',
    },
    tokenAddress: {
      137: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.mai.finance/',
    harvest: true,
    tokenPerBlock: '0.06666666667',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 1000,
    withdrawLockup: 12,
    harvestLockup: 4,
    partnerColor: '52, 136, 147',
  },
  {
    sousId: 3,
    tokenName: 'DAI',
    stakingTokenName: 'DELIRIUM',
    stakingTokenAddress: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    contractAddress: {
      137: '0x22f2AD352bC8D06738De4F5992E9dF3DE20cD06D',
    },
    lpAddress: {
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
    },
    tokenAddress: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://makerdao.com/',
    harvest: true,
    tokenPerBlock: '0.04761904762',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 3000,
    withdrawLockup: 36,
    harvestLockup: 4,
    partnerColor: '255, 157, 61',
  },
  {
    sousId: 1,
    tokenName: 'MAI',
    stakingTokenName: 'DELIGHT',
    stakingTokenAddress: '0x960fE3CBEF6d922eCe9fa3E5611B8f9Ec14DE649',
    contractAddress: {
      137: '0xff1a79915aaab9BAbba10Cc585f69a7c848f6577',
    },
    lpAddress: {
      137: '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954',
    },
    tokenAddress: {
      137: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.mai.finance/',
    harvest: true,
    tokenPerBlock: '0.05333333333',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 2000,
    withdrawLockup: 24,
    harvestLockup: 4,
    partnerColor: '219, 55, 55',
  },
  // {
  //   sousId: 3,
  //   tokenName: 'SPADE',
  //   stakingTokenName: 'DELIRIUM',
  //   stakingTokenAddress: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
  //   contractAddress: {
  //     137: '0xeEa70935C90BfD4A5fA4254F69dAff761Bb062eF',
  //   },
  //   lpAddress: {
  //     137: '0xCcCEc4A90b3435065f5e1feC6346be9Da1B7B5eD',
  //   },
  //   tokenAddress: {
  //     137: '0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
  //   },
  //   quoteTokenAdress: {
  //     137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  //   },
  //   poolCategory: PoolCategory.PARTNERSHIP,
  //   projectLink: 'https://polygonfarm.finance/',
  //   harvest: true,
  //   tokenPerBlock: '0.00390625',
  //   sortOrder: 1,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   stakingTokenDecimals: 18,
  //   stakingLimit: 0,
  //   poolBalance: 1000,
  //   withdrawLockup: 36,
  //   harvestLockup: 2,
  //   partnerColor: '119, 81, 216',
  // },
  // {
  //   sousId: 2,
  //   tokenName: 'CRYSTL',
  //   stakingTokenName: 'DELIRIUM',
  //   stakingTokenAddress: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
  //   contractAddress: {
  //     137: '0xB2cB1D7625f65C7BCc137Cd4c82A847a72Bfc395',
  //   },
  //   lpAddress: {
  //     137: '0xB8e54c9Ea1616beEBe11505a419DD8dF1000E02a',
  //   },
  //   tokenAddress: {
  //     137: '0x76bF0C28e604CC3fE9967c83b3C3F31c213cfE64',
  //   },
  //   quoteTokenAdress: {
  //     137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  //   },
  //   poolCategory: PoolCategory.PARTNERSHIP,
  //   projectLink: 'https://polycrystal.finance/',
  //   harvest: true,
  //   tokenPerBlock: '0.063516260162602',
  //   sortOrder: 1,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   stakingTokenDecimals: 18,
  //   stakingLimit: 0,
  //   poolBalance: 5000,
  //   withdrawLockup: 72,
  //   harvestLockup: 1,
  //   partnerColor: '216, 148, 214',
  // },
  // {
  //   sousId: 1,
  //   tokenName: 'DAI',
  //   stakingTokenName: 'DELIGHT',
  //   stakingTokenAddress: '0x0D30a539F0597F01cA34356515318a4Fb6Ffe7CB',
  //   contractAddress: {
  //     137: '0xc3d8DAE26E105d26Bef5E27362D260a41664729a',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://polycrystal.finance/',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 2,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools
